import React from 'react';
import PropTypes from 'prop-types';
import LabelledInput from './LabelledInput.tsx';
import LoginForm from './LoginForm.tsx';

class Login extends React.Component {

    get FooterHtml() {
        const { forgot, register } = this.props;

        return (
            <div className="row">
                <div className="col-xs-6 text-left">
                    <a className="login-link" onClick={forgot}>Forgot password?</a>
                </div>
                <div className="col-xs-6 text-right">
                    <a className="login-link" onClick={register}>Register</a>
                </div>
            </div>
        );
    }

    getMessage() {
        const { message } = this.props;

        if (message != null && typeof message === 'string' && message.trim().length > 0) {
            return (
                <div className="info message-ticker margin-bottom-20">{message}</div>
            );
        }

        return null;
    }


    render() {
        const { onChange, email, password, loginError } = this.props;

        let error = '';

        if (loginError != null) {
            error = (
                <div className="error message-ticker margin-bottom-20">{loginError}</div>
            );
        }

        return (
            <LoginForm
                title="Log in to your RealTime CEO Account"
                submit={this.props.submit}
                waiting={this.props.waiting}
                footer={this.FooterHtml}
                onSubmit={() => this.props.onSubmit()}
            >
                <div>
                    {this.getMessage()}
                    {error}
                    <LabelledInput
                        name="Email Address"
                        style={{ width: '100%' }}
                        id="email"
                        value={email}
                        type="text"
                        placeholder="example@domain.com"
                        onChange={value => onChange('email', value)}
                        required
                    />

                    <LabelledInput
                        name="Password"
                        id="password"
                        style={{ width: '100%' }}
                        type="password"
                        placeholder="password"
                        value={password}
                        onChange={value => onChange('password', value)}
                        required
                    />


                    <div className="row row-20m">
                        <div className="col-xs-4 text-center" />
                        <div className="col-xs-4 text-center">
                            <button type="submit" className="btn btn-login btn-action">
                                Log in
                            </button>
                        </div>
                    </div>

                </div>
            </LoginForm>
        );
    }
}

Login.propTypes = {
    email: PropTypes.string,
    loginError: PropTypes.string,
    message: PropTypes.string,
    onChange: PropTypes.func,
    password: PropTypes.string,
    submit: PropTypes.func,
    waiting: PropTypes.bool,
};

export default Login;
