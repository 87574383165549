import React from 'react';
import PropTypes from 'prop-types';
import LoginForm from "./LoginForm"
import { ActionButton } from "../../common/RealTimeCeoButton"
class Verify extends React.Component {

    getBodyText() {
        const{ is_new_account, email_address } = this.props;
        if (is_new_account == true) {

            return <div style={{textAlign: 'center'}}>
                <p> We've sent an email to:</p>
                <p> {email_address} </p>
                <p> Open the email and follow the instructions to verify your email.</p>
                <p> If you havent received it in a few minutes, check your Junk folders and that the email 
                    entered above is correct</p>
                {this.getFooterText()}
            </div>
            
        } else {
            
            return <div style={{textAlign: 'center'}}>
                <p> We've already sent an email to:</p>
                <p> {email_address} </p>
                <p> The email address you entered is linked to a subscribed account, however the address is yet to be verified.</p>
                <p> Click the Resend button below to send a new verification email</p>
                <p> If you havent received it in a few minutes, check your Junk folders and that the email
                    entered above is correct</p>
                {this.getFooterText()}
            </div>
        }

    }

    getFooterText() {
        const { onResendVerification } = this.props;

        if (typeof onResendVerification === "function") {
            return  <div>
                        <div className="text-center"> <ActionButton onClick={onResendVerification}>Resend Confirmation Email</ActionButton> </div>
                        <p> If you experience any issues, contact RealTime CEO support at <a href="mailto:support@realtimeceo.com">support@realtimeceo.com</a></p>
                    </div>
        } else {
            return  <div>
                        <div className="text-center"> <span className="positive-text">A new verification email has been sent.</span> </div>
                        <p> If you experience any issues, contact RealTime CEO support at <a href="mailto:support@realtimeceo.com">support@realtimeceo.com</a></p>
                    </div>
        }
    }
    
    getTitle() {
        const {is_new_account} = this.props;

        if (is_new_account == true) {
            return "Account Created"
        } else {
            return "Existing Account"
        }
    }

    render() {
        return <LoginForm title={this.getTitle()} onSubmit={null} footer={null} waiting={this.props.waiting}>
            {this.getBodyText()}
        </LoginForm>

    }
}

Verify.propTypes = {
    is_new_account: PropTypes.bool,
    email_address: PropTypes.string,
};

Verify.defaultProps = {
    is_new_Account: false,
    email_address: "",
    onResendVerification: null
};


export default Verify;