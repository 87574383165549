import React from 'react';
import PropTypes from 'prop-types';
import LabelledInput from "./LabelledInput"
import Form from "./LoginForm"
import IndustryCategories from "../../common/IndustryCategories"

class Registration extends React.Component {
    getIndustriesOptions() {
        return IndustryCategories.map(industry => (
              <option key={industry} value={industry}>{industry}</option>
            )
        );
    }

    getDefaultInputsForRegistration() {
        const { onChange, registrationInfo } = this.props;
        const { first_name, last_name, email, password } = registrationInfo;

        return (
            <div>
                <LabelledInput
                    id="first-name"
                    name="First Name"
                    onChange={value => onChange('first_name', value)}
                    required
                    type="text"
                    value={first_name}
                />
                <LabelledInput
                    id="last-name"
                    name="Last Name"
                    onChange={value => onChange('last_name', value)}
                    required
                    type="text"
                    value={last_name}
                />
                <LabelledInput
                    id="rego-email"
                    name="Email Address"
                    onChange={value => onChange('email', value)}
                    required
                    type="email"
                    value={email}
                />
                <LabelledInput
                    id="rego-password"
                    name="Create Password"
                    onChange={value => onChange('password', value)}
                    required
                    type="password"
                    value={password}
                />
            </div>
        );
    }

    getBusinessInfoInput() {
        const { onChange, registrationInfo, inviteToken } = this.props;
        const { industry, business_name } = registrationInfo;

        if (inviteToken == null) {
            return <div>
                <LabelledInput name="Business Name"
                               id="business"
                               value={business_name} type="text" required
                               onChange={value => onChange("business_name", value)}/>

                <LabelledInput name="Industry">
                    <select id="industry" value={industry} onChange={event => onChange("industry", event.target.value)} required>
                        {this.getIndustriesOptions()}
                    </select>
                </LabelledInput>
            </div>
        }

        return null;
    }

    getSubmitButton() {
        const { onSubmit, inviteToken } = this.props;

        return <div className="row row-10m">
            <div className="col-xs-4 text-center">
            </div>
            <div className="col-xs-4 text-center">
                <button type="submit" className="btn btn-login btn-action">Next</button>
            </div>
        </div>
    }

    getErrorMessage() {
        const { registrationError } = this.props;

        if (registrationError != null) {
            return <div className="error message-ticker margin-bottom-20">{registrationError}</div>
        }

        return null;
    }

    getMessage() {
        const { message } = this.props;

        if (message != null && typeof message == "string" && message.trim().length > 0) {
            return <div className="info message-ticker margin-bottom-20">{message}</div>
        }

        return null;
    }

    getFooterHtmlForForm() {
        const { onCancel } = this.props;

        return <div className="row">
            <div className="col-xs-12 text-center">
                <a className="login-link" onClick={onCancel}>Back to Login</a>
            </div>
        </div>; 
    }

    render() {
        const { onSubmit, waiting, info_text } = this.props;

        return <Form title="Register" waiting={waiting} onSubmit={onSubmit} footer={this.getFooterHtmlForForm()}>
            {info_text}
            <hr></hr>
            {this.getMessage()}
            {this.getErrorMessage()}
            {this.getDefaultInputsForRegistration()}
            {this.getBusinessInfoInput()}
            {this.getSubmitButton()}
        </Form>
    }

}

Registration.propTypes = {
    waiting: PropTypes.bool,
    registrationInfo: PropTypes.object,
    onChange: PropTypes.func,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    message: PropTypes.string,
    token: PropTypes.string,
    inviteToken: PropTypes.string,
    registrationError: PropTypes.string,
    info_text: PropTypes.object
};

Registration.defaultProps = {
    errorMessage: null
};

export default Registration;
