"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * @readonly
 * @enum {string}
 */
var Method;
(function (Method) {
    Method["GET"] = "GET";
    Method["POST"] = "POST";
    Method["PUT"] = "PUT";
    Method["DELETE"] = "DELETE";
})(Method || (Method = {}));
/**
*
* @readonly
* @enum {string}
*/
var EndPoints;
(function (EndPoints) {
    EndPoints["Auth"] = "auth";
    EndPoints["Month"] = "month";
    EndPoints["Account"] = "account";
    EndPoints["AccountItem"] = "account_item";
    EndPoints["Tag"] = "tag";
    EndPoints["TagCategory"] = "tag_category";
    EndPoints["Field"] = "field";
    EndPoints["FieldMapping"] = "field_mapping";
    EndPoints["AccountNumberTag"] = "account_number_tag";
    EndPoints["ThreeW"] = "threew";
    EndPoints["ThreeWTag"] = "threewtag";
    EndPoints["SonQuestion"] = "sonquestion";
    EndPoints["SonAnswer"] = "sonanswer";
    EndPoints["MonthAccountItems"] = "month_account_items";
    EndPoints["MonthBatch"] = "month/batch";
    EndPoints["AccountItemBatch"] = "account_item/batch";
    EndPoints["Register"] = "register";
    EndPoints["Subscription"] = "subscription";
    EndPoints["SubscriptionPrice"] = "subscription/current_default_plan";
    EndPoints["Coupon"] = "subscription/coupon";
    EndPoints["User"] = "user";
    EndPoints["UserActivate"] = "user/activate";
    EndPoints["UserPasswordReset"] = "user/password/reset";
    EndPoints["UserResendVerify"] = "user/verify";
})(EndPoints || (EndPoints = {}));
/**
 * @class
 */
class AjaxEndpoint {
    constructor(appName, root) {
        this.appName = appName;
        this.root = root;
        this.token = null;
    }
    /**
     *
     * @param {Response} response
     * @returns {*}
     */
    validateResponse(response) {
        if (typeof response.status !== 'undefined') {
            // Something has gone wrong, we were expecting a month object.
            if (response.status === 404) {
                throw response;
            }
        }
        return response;
    }
    /**
     *
     * @param {AuthTokenResponse} authTokenResponse
     * @returns {AuthTokenResponse}
     */
    setAuthToken(authTokenResponse) {
        if (authTokenResponse.token != null) {
            this.token = authTokenResponse.token;
            console.info('Auth Token', authTokenResponse.token);
        }
        return authTokenResponse;
    }
    /**
     *  Returns AuthToken
     *
     *  @returns {string} token
     */
    get AuthToken() {
        return this.token;
    }
    /**
     *  Returns a header object.
     *
     * @returns {{Content-Type: string}}
     */
    get contentTypeHeader() {
        return {
            'Content-Type': 'application/json',
        };
    }
    /**
     *  Returns a header object with an authorization token attached.
     *
     * @returns {{Content-Type: string, Authorization: string}}
     */
    get authHeader() {
        return {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`,
        };
    }
    /**
     *
     * Logs to the console with the result.
     *
     * @param {string} title
     * @param {*} result
     */
    log(title, result) {
        // eslint-disable-next-line no-console
        console.log(title, result);
    }
    /**
     *
     */
    checkReachability() {
        return Promise.resolve(() => {
        });
    }
    /**
     *
     * Authenticates the user and returns an authorization token.
     *
     * @param {string} email
     * @param {string} password
     * @returns {Promise} Returns a promise with the Authorization token
     */
    auth(email, password) {
        return this.request([EndPoints.Auth], Method.POST, { email: email, password: password }, this.authHeader);
    }
    /**
     * Register a user and account.
     * @returns {Promise}
     */
    registration(registrationData) {
        let path = EndPoints.Register;
        let data = registrationData;
        return this.request([path], Method.POST, data, this.contentTypeHeader);
    }
    activateUser(params) {
        return this.request([EndPoints.UserActivate], Method.POST, params, this.contentTypeHeader);
    }
    getUserDetailsFromInvitationToken(token) {
        return this.request([EndPoints.UserActivate], Method.GET, { invitation_token: token }, this.contentTypeHeader);
    }
    /**
     * Cancels a user's subscription
     * @returns {Promise}
     */
    cancelSubscription(subscriptionData) {
        let path = EndPoints.Subscription;
        let data = subscriptionData;
        return this.request([path], Method.DELETE, data, this.authHeader);
    }
    /**
     * Updates a subscription or creates a new subscription if one has not been created.
     * @returns {Promise}
     */
    setSubscriptionInfo(subscriptionData) {
        let path = EndPoints.Subscription;
        let data = subscriptionData;
        return this
            .request([path], Method.PUT, data, this.authHeader)
            .then(this.validateResponse)
            .catch(() => {
            return this.request([path], Method.POST, data, this.authHeader);
        })
            .then(response => {
            return response;
        });
    }
    submitPaymentDetails(payment, authToken) {
        if (payment.coupon.length == 0) {
            delete payment.coupon;
        }
        if (authToken == null) {
            return Promise.reject(new Error('Auth token is null.'));
        }
        if (payment == null) {
            return Promise.reject(new Error('Payment info has not been supplied.'));
        }
        const path = EndPoints.Subscription;
        const data = payment;
        const header = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        };
        return this.request([path], Method.POST, data, header);
    }
    resetPassword(recoveryEmail) {
        let path = EndPoints.UserPasswordReset;
        return this.request([path], Method.POST, { email: recoveryEmail }, this.authHeader);
    }
    checkCoupon(couponCode) {
        let path = EndPoints.Coupon;
        return this.request([path], Method.GET, { coupon_code: couponCode }, this.authHeader);
    }
    getSubscriptionPrice() {
        let path = EndPoints.SubscriptionPrice;
        // TODO: test this, wasn't previouslypassing third argument
        return this.request([path], Method.GET, '', this.authHeader);
    }
    resendVerificationEmail() {
        let path = EndPoints.UserResendVerify;
        return this.request([path], Method.POST, {}, this.authHeader);
    }
    /**
     *
     * @param {Object} nameObj - name: string
     * @returns {Promise}
     */
    getAccount() {
        return this.request([EndPoints.Account], Method.GET, {}, this.authHeader);
    }
    getUser() {
        return this.request([EndPoints.User], Method.GET, {}, this.authHeader);
    }
    /**
     *
     * URI Encodes an object
     *
     * @param {Object|array} obj
     * @returns {string}
     */
    serializeObject(obj) {
        let str = [];
        for (let p in obj) {
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
            }
        }
        return str.join('&');
    }
    /**
     *
     * Makes AJAX call and returns a promise of the result.
     *
     */
    request(paths, method, params, headers) {
        let path = paths.reduce((prev, curr) => {
            return `${prev}/${curr}`;
        }, '');
        let url = `${this.root}${this.appName}${path}`;
        let options = {
            method: method,
            headers: headers || {}, // fetch dies in Safari if headers is undefined
        };
        if (method === Method.POST || method === Method.PUT) {
            options.body = JSON.stringify(params);
        }
        if (method === Method.GET && typeof params == 'object') {
            url += '?' + this.serializeObject(params);
        }
        return fetch(url, options)
            .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.json();
            }
            throw response;
        });
    }
}
exports.default = AjaxEndpoint;
