import React from 'react';
import PropTypes from 'prop-types';
import LabelledInput from './LabelledInput.tsx';
import ForgotPasswordForm from './LoginForm.tsx';

class ForgotPassword extends React.Component {
    getFooterHtml() {
        const { onBackPressed } = this.props;

        return (
            <div className="row">
                <div className="col-xs-12 text-center">
                    <a className="login-link" onClick={onBackPressed}>Back to Login</a>
                </div>
            </div>
        );
    }

    getWaitingIndicator() {
        const { waiting } = this.props;

        if (waiting) {
            return (
                <div className="login-form-cover">
                    <img src="/images/login_loading_indicator.gif" alt="loading" />
                </div>
            );
        }

        return null;
    }

    getInfoMessage() {
        const { infoMessage } = this.props;

        if (infoMessage == null) {
            return null;
        }

        return (
            <div className="info message-ticker margin-bottom-20">{infoMessage}</div>
        );
    }

    getErrorMessage() {
        const { errorMessage } = this.props;

        if (errorMessage == null) {
            return null;
        }

        return <div className="error message-ticker margin-bottom-20">Error: {errorMessage}</div>;
    }

    render() {
        const { onSubmit, recoveryEmail, onChange } = this.props;

        const info = this.getInfoMessage();

        const body = (
            <div>{this.getErrorMessage()}
                <p>Enter your email address, and a password reset link will be sent.</p>
                <LabelledInput
                    name="Email"
                    id="recovery-email"
                    type="email"
                    placeholder="example@domain.com"
                    value={recoveryEmail}
                    required
                    onChange={value => onChange(value)}
                />

                <div className="row row-20m">
                    <div className="col-xs-offset-4 col-xs-4 text-center">
                        <button type="submit" className="btn btn-login btn-action">Send</button>
                    </div>
                </div>
                {this.getWaitingIndicator()}
            </div>
        );

        return (
            <ForgotPasswordForm
                title="Recover Password"
                footer={this.getFooterHtml()}
                onSubmit={onSubmit}
            >
                {info == null ? body : info}

            </ForgotPasswordForm>
        );
    }
}

ForgotPassword.propTypes = {
    errorMessage: PropTypes.string,
    infoMessage: PropTypes.string,
    onBackPressed: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    recoveryEmail: PropTypes.string.isRequired,
    waiting: PropTypes.bool,
};

export default ForgotPassword;
